<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-text>
          <div>
            <v-sheet
              tile
              height="54"
              color="grey lighten-3"
              class="d-flex"
            >
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer />
              <v-card-title>
                {{ $t('general.timetable') }}
                <v-toolbar-title v-if="$refs.calendar" class="ml-3">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
              </v-card-title>
              <v-spacer />
              <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next()"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet height="600">
              <v-progress-linear
                v-if="!Lb_IsLoaded"
                class="my-1"
                indeterminate
                color="primary"
              />
              <!-- {0} show the Count of more events in the Table Column -->
              <v-calendar
                :event-more-text="'{0} ' +$t('general.more')"
                locale="CH"
                color="primary"
                ref="calendar"
                v-model="value"
                :interval-format="intervalFormat"
                :first-interval= 7
                :interval-count= 17
                :weekdays="weekday"
                type="week"
                :events="La_Events"
                event-overlap-mode="column"
                :event-color="getEventColor"
                @click:event="showEvent"
              >
                <template v-slot:event="{ event }">
                  {{event.description}}
                </template>
              </v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card
                  color="grey lighten-4"
                  min-width="350px"
                  flat
                >
                  <v-toolbar
                    :color="selectedEvent.color"
                    dark
                  >
                    <v-toolbar-title v-html="selectedEvent.name" />
                    <v-spacer />
                    <v-btn
                      icon
                      @click="selectedOpen = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    {{$t('general.room')}} : <span v-html="selectedEvent.room" /><br>
                    {{$t('general.teacher')}} : <span v-html="selectedEvent.teacher" />
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-sheet>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { get } from '../../../worker/worker-api';
import { mapGetters } from 'vuex'
export default {
  metaInfo() {
    return{
      // title will be injected into parent titleTemplate
      title: this.$t('general.timetable'),
    }
  },
  props: {
  // AdressRolleID from Child
  childId: {
    type: Number,
    default: 0
    }
  },  
  data () {
      return {
        weekday: [1, 2, 3, 4, 5, 6, 0],
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        value: '',
        La_Events: [],
        Lb_IsLoaded:false,
        i_AddressRoleID: null,
      }
  },
  watch: {
    '$store.state.authData.o_CurrentPlanningPeriod.planningPeriodID': function() {
      if (this.childId != 0){
        this.getEvents(this.childId, this.getPlanningPeriodID);
      }// if
      else{
        this.getEvents(this.i_AddressRoleID, this.getPlanningPeriodID);
      }// else
    }
  },
  computed: {
    ...mapGetters(['getPlanningPeriodID', 'getImpersonation']),
  },
  methods: {
    intervalFormat(interval) {//get 24H format
      return interval.time
    },
    
    getEventColor (event) {
      return event.color
    },

    showEvent ({ nativeEvent, event }) {
      const open = () => {
      this.selectedEvent = event
      this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } 
      else {
        open()
      }
      nativeEvent.stopPropagation()
    },

    getEvents(Ai_SSP_AddressRoleID,Ai_PlanningPeriodID) {
      if (Ai_SSP_AddressRoleID === null) return
      store.dispatch('changeThemeLoadingState', true);
      this.Lb_IsLoaded =false;
      return get(`${process.env.VUE_APP_SMT_API_URL}/student/${Ai_SSP_AddressRoleID}/timetable/planning-period/${Ai_PlanningPeriodID}`, {})
        .then(response => {
          this.La_Events = response.events;
          setTimeout(() => store.dispatch('changeThemeLoadingState', false), 700)
          this.Lb_IsLoaded = true;
        }).catch(error => {
          this.Lb_IsLoaded = true;
          store.dispatch('changeThemeLoadingState', false);
        });
    },
  },
  beforeMount () {
    if (this.getImpersonation.b_Status) this.i_AddressRoleID = this.getImpersonation.i_AddressRoleID
    else this.i_AddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role)
    if (this.childId != 0){
      this.getEvents(this.childId, this.getPlanningPeriodID);
    }// if
    else{
      this.getEvents(this.i_AddressRoleID, this.getPlanningPeriodID);
    }// else
  },
  created () { 
  },

}
</script>
